<template>
  <div class="home-wrapper--visas-and-restrictions">
    <LadContainerMediaText :with-title="false">
      <template slot="description">
        <h2 class="local-flex--title">{{ $t(`${base}.title`) }}</h2>
        <p>{{ $t(`${base}.description`) }}</p>

        <nuxt-link to="/visas-and-restrictions">
          <el-button type="info">
            <span>{{ $t(`${base}.more_information`) }}</span>
          </el-button>
        </nuxt-link>
      </template>

      <LadImage
        slot="image"
        src="https://img.freepik.com/vector-gratis/ilustracion-grafica-ubicacion-mapa-continente-mundial_53876-6448.jpg"
        :max-width="200"
      />
    </LadContainerMediaText>
  </div>
</template>

<script>
import CacheComponentMixin from '@/utils/mixin/cache-components'

export default {
  name: 'LadHomeVisasAndRestrictions',
  components: {
    LadContainerMediaText: () =>
      import(
        /* webpackChunkName: "lad-container-media-text" */ '@/components/container-media-text/index'
      ),
  },
  mixins: [CacheComponentMixin],
  data() {
    return {
      base: 'visas_restrictions',
    }
  },
}
</script>

<style lang="scss">
.home-wrapper--visas-and-restrictions {
  background-color: white;

  .lad-container-media-text--right {
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 650px) {
    .el-main {
      padding-top: 0;
    }
  }

  h2.local-flex--title {
    font-size: 2em;
    margin: 0.67em 0;
  }
}
</style>
